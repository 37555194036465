<template>
    <div
        class="widget-view"
        v-if="element && element.key"
        :class="[{ active: selectWidget.key == element.key, 'sr-only': element.isCustom}]"
        @click.stop="handleSelectWidget(index)"
    >
        <div class="d-flex justify-content-between">
            <label
                v-if="!element.options.hideLabel"
                class="form-item-label"
                :class="{ is_req: element.options.required }"
            >
                {{ !element.isCustom ? element.label : "" }}
            </label>
            <i
                v-if="element.options.showTooltip"
                class="fas fa-info-circle text-primary"
                v-b-popover.hover.auto="element.toolTipText"
            >
            </i>
        </div>
        <template v-if="element.type == 'input'">
            <b-form-input
                :key="element.key"
                v-model="element.options.defaultValue"
                :style="{ width: element.options.width }"
                :placeholder="element.options.placeholder"
                :disabled="element.options.disabled"
            ></b-form-input>
        </template>

        <template v-if="element.type == 'textarea'">
            <b-form-textarea
                v-model="element.options.defaultValue"
                :style="{ width: element.options.width }"
                :disabled="element.options.disabled"
                :plaintext="element.options.plaintext"
                :placeholder="element.options.placeholder"
                :rows="element.options.rows"
                :max-rows="element.options.maxRows"
            ></b-form-textarea>
        </template>

        <template v-if="element.type == 'number'">
            <b-form-input
                type="number"
                v-model="element.options.defaultValue"
                :disabled="element.options.disabled"
                :style="{ width: element.options.width }"
            ></b-form-input>
        </template>

        <template v-if="element.type == 'radio'">
            <b-form-radio-group
                v-model="element.options.defaultValue"
                :style="{ width: element.options.width }"
                :disabled="element.options.disabled"
                :stacked="!element.options.inline"
            >
                <b-form-radio
                    :value="item.value"
                    v-for="(item, index) in element.options.options"
                    :key="item.value + index"
                >
                    {{ element.options.showLabel ? item.label : item.value }}
                </b-form-radio>
            </b-form-radio-group>
        </template>

        <template v-if="element.type == 'checkbox'">
            <b-form-checkbox-group
                v-model="element.options.defaultValue"
                :style="{ width: element.options.width }"
                :disabled="element.options.disabled"
                :stacked="!element.options.inline"
            >
                <b-form-checkbox
                    :value="item.value"
                    v-for="(item, index) in element.options.options"
                    :key="item.value + index"
                >
                    {{ element.options.showLabel ? item.label : item.value }}
                </b-form-checkbox>
            </b-form-checkbox-group>
        </template>

        <template v-if="element.type == 'date'">
            <b-form-datepicker
                v-model="element.options.defaultValue"
                :placeholder="element.options.placeholder"
                :readonly="element.options.readonly"
                :disabled="element.options.disabled"
                :style="{ width: element.options.width }"
                :date-format-options="{
                    year: '2-digit',
                    month: '2-digit',
                    day: '2-digit',
                }"
                v-bind="labels[locale] || {}"
                :locale="locale"
                :start-weekday="1"
            >
            </b-form-datepicker>
        </template>

        <template v-if="element.type == 'select'">
            <b-form-select
                v-model="element.options.defaultValue"
                :disabled="element.options.disabled"
                :style="{ width: element.options.width }"
            >
                <b-form-select-option
                    v-for="item in element.options.options"
                    :key="item.value"
                    :value="item.value"
                    >{{
                        element.options.showLabel ? item.label : item.value
                    }}</b-form-select-option
                >
            </b-form-select>
        </template>

        <template v-if="element.type == 'switch'">
            <b-form-checkbox
                v-model="element.options.defaultValue"
                :disabled="element.options.disabled"
                switch
            >
            </b-form-checkbox>
        </template>

        <template v-if="element.type == 'file'">
            <st-file
                :acceptedFileTypes="element.options.acceptedFileTypes"
                :maxFileSize="Number(element.options.maxFileSize)"
                :max="Number(element.options.max)"
            ></st-file>
        </template>

        <template v-if="element.type == 'editor'">
            <vue-editor
                v-model="element.options.defaultValue"
                :style="{ width: element.options.width }"
            >
            </vue-editor>
        </template>

        <template v-if="element.type == 'text'">
            <div
                :class="[element.options.fontSize,{ 'font-weight-bold': element.options.isBold }]"
            >
                {{ element.options.defaultValue }}
            </div>
        </template>

        <template v-if="element.type == 'html'">
            <div class="ql-snow">
                <div
                    class="ql-editor"
                    v-html="element.options.defaultValue"
                ></div>
            </div>
        </template>

        <template v-if="element.type === 'beneficiary-list'">
            <beneficiary-list ref="beneficiaryListWidget" />
        </template>

        <template v-if="element.type === 'applicant-form'">
            <applicant-form ref="applicantFormWidget" />
        </template>

        <template v-if="element.type === 'real-estate-list'">
            <real-estate-list ref="realEstateListWidget" />
        </template>

        <template v-if="element.type === 'decision-list'">
            <application-decision-list ref="decisionListWidget" />
        </template>

        <template v-if="element.type === 'attachments'">
            <application-attachments
                ref="attachmentsWidget"
                :title="element.label"
            ></application-attachments>
        </template>

        <template v-if="element.type === 'urbanism-regulation-picker'">
            <urbanism-regulation-picker
                ref="urbanismRegulationWidget"
            ></urbanism-regulation-picker>
        </template>

        <template v-if="element.type === 'related-application'">
            <related-application
                ref="relatedApplicationWidget"
                :showLabel="!element.options.hideLabel"
                :label="element.label"
                :isRequired="element.options.required"
                :acceptedFileTypes="element.options.acceptedFileTypes"
                :maxFiles="element.options.maxFiles"
                :maxFileSize="element.options.maxFileSize"
            ></related-application>
        </template>

        <template v-if="element.type === 'offender-list'">
            <offender-list ref="offenderListWidget" />
        </template>

        <template v-if="element.type === 'witness-list'">
            <witness-list ref="witnessListWidget" />
        </template>

        <template v-if="element.type === 'realty-list'">
            <realty-list ref="realtyListWidget"/>
        </template>

        <template v-if="element.type === 'staff-form'">
            <staff-form ref="staffFormWidget" />
        </template>

        <template v-if="element.type == 'divider'">
            <hr />
        </template>

        <template v-if="element.type === 'gis-attachments'">
            <application-gis-attachments
                ref="gisAttachmentsWidget"
                :title="element.label"
                :acceptedFileTypes="element.options.acceptedFileTypes"
                :maxFiles="Number(element.options.maxFiles)"
                :maxFileSize="Number(element.options.maxFileSize)"
            ></application-gis-attachments>
        </template>

        <template v-if="element.type === 'ac-tax-validator'">
            <ac-tax-validator
                ref="acTaxValidatorWidget"
                :title="element.label"
                :coeff="element.options.coeff"
                :taxCategories="element.options.acTaxCategories"
            ></ac-tax-validator>
        </template>

        <template v-if="element.type === 'locality-list'">
            <locality-list ref="localityListWidget"/>
        </template>

        <template v-if="element.type === 'buildings-list'">
            <buildings-list ref="buildingsListWidget"/>
        </template>

        <template v-if="element.type === 'urbanism-regulations-list'">
            <urbanism-regulations-list
                :selectedUtrDocumentTemplate="element.options.selectedUtrDocumentTemplate"
                ref="urbanismRegulationsListWidget"
            ></urbanism-regulations-list>
        </template>

        <div class="widget-view-action" v-if="selectWidget.key == element.key">
            <i class="far fa-clone" @click.stop="handleWidgetClone(index)"></i>
            <i class="fas fa-trash" @click.stop="handleWidgetDelete(index)"></i>
        </div>

        <div class="widget-view-drag" v-if="selectWidget.key == element.key">
            <i class="fas fa-arrows-alt drag-widget"></i>
        </div>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import i18nService from "@/shared/services/i18n.service";

export default {
    props: ["element", "select", "index", "data"],
    components: {
        VueEditor,
        BeneficiaryList: () => import("@/modules/applications/components/beneficiary/BeneficiaryList.vue"),
        RealEstateList: () => import("@/modules/applications/components/real-estate/RealEstateList.vue"),
        ApplicantForm: () => import("@/modules/applications/components/applicant/ApplicantForm"),
        ApplicationDecisionList: () => import("@/modules/applications/components/decision/ApplicationDecisionList"),
        ApplicationAttachments: () => import("@/modules/applications/components/attachments/ApplicationAttachments"),
        UrbanismRegulationPicker: () => import("@/modules/applications/components/urbanism-regulation/ApplicationUrbanismRegulation"),
        RelatedApplication: () => import("@/modules/applications/components/related-application/RelatedApplication"),
        StFile: () => import("@/shared/components/StFile"),
        OffenderList: () => import("@/modules/control/components/offender/OffenderList.vue"),
        WitnessList: () => import("@/modules/control/components/witness/WitnessList.vue"),
        RealtyList: () => import("@/modules/control/components/realty/RealtyList.vue"),
        StaffForm: () => import("@/modules/control/components/staff/StaffForm.vue"),
        ApplicationGisAttachments: () => import("@/modules/applications/components/gis-attachments/ApplicationGisAttachments"),
        AcTaxValidator: () => import("@/modules/applications/components/ac-tax-validator/AcTaxValidator.vue"),
        LocalityList: () => import("@/modules/applications/components/localities/LocalityList.vue"),
        BuildingsList: () => import("@/modules/applications/components/buildings/BuildingsList.vue"),
        UrbanismRegulationsList: () => import("@/modules/applications/components/urbanism-regulations-edit/UrbanismRegulationsList.vue"),
    },
    data() {
        return {
            selectWidget: this.select,
            locale: i18nService.getActiveLanguage(),
            labels: {
                ro: {
                    labelNoDateSelected: this.$t("GENERAL.DATEPICKER.SELECT_DATE"),
                    labelHelp: this.$t("GENERAL.DATEPICKER.HELP"),
                },
            },
        };
    },
    created() {},
    methods: {
        handleSelectWidget(index) {
            this.selectWidget = this.data.list[index];
        },
        handleWidgetDelete(index) {
            if (this.data.list.length - 1 === index) {
                if (index === 0) {
                    this.selectWidget = {};
                } else {
                    this.selectWidget = this.data.list[index - 1];
                }
            } else {
                this.selectWidget = this.data.list[index + 1];
            }

            this.$nextTick(() => {
                this.data.list.splice(index, 1);
            });
        },
        handleWidgetClone(index) {
            const key =
                Date.parse(new Date().toString()) +
                "_" +
                Math.ceil(Math.random() * 99999);
            let cloneData = {
                ...this.data.list[index],
                key,
                model: this.data.list[index].type + "_" + key,
                name: this.data.list[index].type + "_" + key,
                rules: this.data.list[index].rules || [],
            };

            const inputTypes = ["radio", "checkbox", "select"];

            if (inputTypes.includes(this.data.list[index].type)) {
                cloneData = {
                    ...cloneData,
                    options: {
                        ...cloneData.options,
                        options: cloneData.options.options.map((item) => ({...item})),
                    },
                };
            }

            this.data.list.splice(index, 0, cloneData);

            this.$nextTick(() => {
                this.selectWidget = this.data.list[index + 1];
            });
        },
    },
    watch: {
        select(val) {
            this.selectWidget = val;
        },
        selectWidget: {
            deep: true,
            handler(val) {
                this.$emit("update:select", val);
            },
        },
    },
};
</script>
